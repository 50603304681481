import { NextComponentType } from 'next'
import { AppContext, AppInitialProps, AppProps } from 'next/app'
import '../assets/style/app.scss'
import { NextWebVitalsMetrics, NextWebVitalsMetricsReport, sendWebVitals } from '../modules/amplitude/amplitude'
import '../modules/sentry/sentry'
import { isProd } from '../util/config'
import { v1 as uuid } from 'uuid'
import size from 'lodash.size'
import { createLogger } from '../util/logger'
import { _AppBootstrap } from '../app/AppBootstrap'

const fileLabel = '_app'
const logger = createLogger({
    label: fileLabel,
})

/*
    ?! _AppBootstrap -> ApolloInitialization -> AppBootstrap
*/
const App: React.FC = (props): JSX.Element => {
    return <_AppBootstrap {...props} />
}

const globalWebVitalsMetric: NextWebVitalsMetricsReport = {
    reportId: uuid(),
    metrics: {},
    reportedCount: 0,
}

export function reportWebVitals(metrics: NextWebVitalsMetrics): void {
    const { name } = metrics
    const count = globalWebVitalsMetric.reportedCount
    globalWebVitalsMetric.metrics[name] = metrics
    const keysLength = size(Object.keys(globalWebVitalsMetric.metrics))
    if ((count === 0 && keysLength === 5) || (count > 0 && keysLength === 2)) {
        sendWebVitals(globalWebVitalsMetric)
        globalWebVitalsMetric.metrics = {}
        globalWebVitalsMetric.reportedCount++
    }
}

export default App
